<template>
    <div>
        <div
            class="card-collection"
            @click="onClickItem($event)"
        >
            <div
                v-if="data.author || showBtnEdit"
                class="card-collection-header"
            >
                <router-link
                    v-if="data.author"
                    class="card-collection-avatar"
                    :to="getAuthorRoute(data.author)"
                >
                    <base-avatar
                        class="me-2"
                        :data="{
                            id: data.author.id,
                            nameInitials: data.author.nameInitials
                        }"
                    />
                    <div class="alias">
                        {{ data.author.alias }}
                    </div>
                </router-link>
                <btn-collection-edit
                    v-if="showBtnEdit"
                    :data="{
                        id: data.id,
                        title: data.name,
                    }"
                    @updatedCollection="$emit('updatedCollection')"
                />
            </div>

            <router-link
                class="img-responsive img-responsive-2by1 card-collection-img"
                :to="`/c-${data.id}`"
            >
                <base-img
                    v-if="data.coverImg"
                    :src="getImageSrc(data.coverImg)"
                    :alt="data.name"
                    :srcset="getImageSrcset(data.coverImg)"
                    :sizes="getImageSizes()"
                    :lazy="imageLoading === 'lazy'"
                />
                <span
                    v-else
                    v-html="$t('user_collections_list_noCover')"
                />
            </router-link>

            <div class="card-collection-details">
                <router-link
                    class="card-title"
                    :to="`/c-${data.id}`"
                >
                    {{ data.name }}
                </router-link>
                <div class="card-subtitle">
                    {{ data.productCount }} {{ $t('other_028') }}
                </div>
            </div>

            <div
                v-if="showBtnsActions"
                class="card-collection-body"
            >
                <btn-like
                    :id="data.id"
                    :type="LIKE.COLLECTION"
                    :likes="data.likes?.totalCount"
                    :liked="$store.getters['user/isLoggedin']
                        ? data.likes?.currentUserLiked
                        : false"
                />
                <div>
                    <share
                        btn-text
                        :url="`/c-${data.id}`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { LIKE, BREAKPOINTS, BREAKPOINTS_DEVICES } from '../utils/constants';
import BaseAvatar from './BaseAvatar.vue';
import BtnCollectionEdit from './BtnCollectionEdit.vue';
import { getAuthorRoute, getSrcCF } from '../utils';

const BtnLike = defineAsyncComponent(() => import('./BtnLike.vue'));
const Share = defineAsyncComponent(() => import('./Share.vue'));

const breakpoints = {
    motoGPower: BREAKPOINTS_DEVICES.motoGPower,
    ...BREAKPOINTS,
};

export default {
    name: 'CardCollection',

    components: {
        BaseAvatar,
        BtnCollectionEdit,
        BtnLike,
        Share,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        showBtnEdit: {
            type: Boolean,
            default: false,
        },
        showBtnsActions: {
            type: Boolean,
            default: false,
        },
        imageLoading: {
            type: String,
            default: 'lazy',
        },
    },

    created() {
        this.LIKE = LIKE;
    },

    methods: {
        getAuthorRoute,

        onClickItem(event) {
            const path = event.path || event.composedPath?.();
            const clickedOnActions = path.find((current) => {
                if (
                    current instanceof HTMLAnchorElement
                    || current instanceof HTMLButtonElement
                ) return true;

                return false;
            });

            if (clickedOnActions) return;

            this.$router.push(`/c-${this.data.id}`);
        },

        formatDate(date) {
            const langIso = this.$store.state.params.api.lang.iso;
            return new Date(date).toLocaleDateString(langIso, {});
        },

        getImageSrc(src) {
            return getSrcCF(src, '252x126');
        },

        getImageSrcset(src) {
            return `
                ${getSrcCF(src, '252x126')} 252w,
                ${getSrcCF(src, '386x193')} 386w,
                ${getSrcCF(src, '472x236')} 472w
            `;
        },

        getImageSizes() {
            return `
                (max-width: ${breakpoints.motoGPower}px) 252px,
                (min-width: ${breakpoints.motoGPower + 1}px) and (max-width: ${breakpoints.sm}px) 386px,
                (min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.lg}px) 472px,
                (min-width: ${breakpoints.lg + 1}px) 386px
            `;
        },
    },
};
</script>

<style lang="scss">
@import "../scss/05-components/card-collection";
</style>
