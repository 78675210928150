var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "card-collection",
        on: {
          click: function ($event) {
            return _vm.onClickItem($event)
          },
        },
      },
      [
        _vm.data.author || _vm.showBtnEdit
          ? _c(
              "div",
              { staticClass: "card-collection-header" },
              [
                _vm.data.author
                  ? _c(
                      "router-link",
                      {
                        staticClass: "card-collection-avatar",
                        attrs: { to: _vm.getAuthorRoute(_vm.data.author) },
                      },
                      [
                        _c("base-avatar", {
                          staticClass: "me-2",
                          attrs: {
                            data: {
                              id: _vm.data.author.id,
                              nameInitials: _vm.data.author.nameInitials,
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "alias" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.data.author.alias) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showBtnEdit
                  ? _c("btn-collection-edit", {
                      attrs: {
                        data: {
                          id: _vm.data.id,
                          title: _vm.data.name,
                        },
                      },
                      on: {
                        updatedCollection: function ($event) {
                          return _vm.$emit("updatedCollection")
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass:
              "img-responsive img-responsive-2by1 card-collection-img",
            attrs: { to: `/c-${_vm.data.id}` },
          },
          [
            _vm.data.coverImg
              ? _c("base-img", {
                  attrs: {
                    src: _vm.getImageSrc(_vm.data.coverImg),
                    alt: _vm.data.name,
                    srcset: _vm.getImageSrcset(_vm.data.coverImg),
                    sizes: _vm.getImageSizes(),
                    lazy: _vm.imageLoading === "lazy",
                  },
                })
              : _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("user_collections_list_noCover")),
                  },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-collection-details" },
          [
            _c(
              "router-link",
              { staticClass: "card-title", attrs: { to: `/c-${_vm.data.id}` } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.data.name) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-subtitle" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.data.productCount) +
                  " " +
                  _vm._s(_vm.$t("other_028")) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showBtnsActions
          ? _c(
              "div",
              { staticClass: "card-collection-body" },
              [
                _c("btn-like", {
                  attrs: {
                    id: _vm.data.id,
                    type: _vm.LIKE.COLLECTION,
                    likes: _vm.data.likes?.totalCount,
                    liked: _vm.$store.getters["user/isLoggedin"]
                      ? _vm.data.likes?.currentUserLiked
                      : false,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("share", {
                      attrs: { "btn-text": "", url: `/c-${_vm.data.id}` },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }